<template>
    <div>
        <b-card title="Gestion potentiels TF1 / Export de potentiels TF1 par marque">
            <b-form-group id="selectTask" label="Votre besoin (à choisir obligatoirement) : " label-for="selectTask">
                <b-form-select id="selectTask" v-model="selectTask" :options="tasks"></b-form-select>
            </b-form-group>

            <b-form-group v-if="selectTask === 'potentials-export-by-brand'" id="selectBrand" label="Listes des marques : " label-for="selectBrand">
                <b-form-select id="selectBrand" v-model="selectBrand" :options="brands"></b-form-select>
            </b-form-group>
            <b-form-group v-if="selectTask === 'potentials-management'" id="postCodes" label="Listes des codes postaux : " label-for="postCodes">
                <b-form-input v-model="postCodes" id="feedback-postCodes" placeholder="77540,77120,77131"></b-form-input>
            </b-form-group>
            <b-form-group id="selectCriteres" label="Listes des critères : " label-for="selectCriteres">
                <b-form-select id="selectCriteres" v-model="selectCriteria" :options="getCriteriaTF1"></b-form-select>
            </b-form-group>
            <b-form-group id="selectChannels" label="Listes des chaines : " label-for="selectChannels">
                <b-form-select id="selectChannels" v-model="SiteGroup" multiple :options="channels"></b-form-select>
            </b-form-group>
            <b-form-group id="format" label="Listes des formats : " label-for="format">
                <b-form-select id="format" v-model="format" :options="formats"></b-form-select>
            </b-form-group>
            <b-form-group id="startDate" label="sélectionner la date de début : " label-for="startDate">
                <b-form-datepicker v-model="startDate" :date-disabled-fn="disabledStartDate" placeholder="Choisir une date"></b-form-datepicker>
            </b-form-group>
            <b-form-group v-if="!stateStartDate">
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <span style="color: #fb4d4d; position: relative; top: -5px; left: 10px">La date de début doit être inférieure ou égale à la date de fin.</span>
            </b-form-group>

            <b-form-group id="endDate" label="sélectionner la date de fin (inclus) : " label-for="endDate">
                <b-form-datepicker v-model="endDate" :date-disabled-fn="disabledEndDate" placeholder="Choisir une date"></b-form-datepicker>
            </b-form-group>
            <b-form-group v-if="!stateEndDate">
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <span style="color: #fb4d4d; position: relative; top: -5px; left: 10px">La date de début et date de fin doivent être dans la même année.</span>
            </b-form-group>

            <b-row class="mt-2 mr-0 justify-content-end">
                <b-button
                    v-if="selectTask === 'potentials-management'"
                    :variant="disabled.potentialsManagement ? 'secondary' : 'success'"
                    @click="getPotential()"
                    :disabled="disabled.potentialsManagement"
                >
                    Valider pour la gestion des potentiels
                </b-button>
                <b-button
                    v-if="selectTask === 'potentials-export-by-brand'"
                    :variant="disabled.exportByBrand ? 'secondary' : 'success'"
                    :disabled="disabled.exportByBrand"
                    @click="getPotentialByBrand()"
                >
                    Exporter des potentiels par marque et envoyer par mail
                </b-button>
            </b-row>
            <b-row class="ml-0" v-if="getAvailableImpressions && !spinner && showAvailableImpressions">
                <strong>
                    Le nombre d'impressions possible pour ces codes postaux sur {{ getNbDay }} jour(s) est : {{ getAvailableImpressions }} <br /><br />
                    À raison de {{ Math.round(getAvailableImpressions / getNbDay) }} par jour.
                </strong> </b-row
            ><br />
            <b-row style="display: flex; justify-content: center" v-if="spinner">
                <b-spinner style="width: 4rem; height: 4rem; color: #7336f0" label="Large Spinner"></b-spinner>
            </b-row>
        </b-card>

        <b-row style="display: flex; justify-content: center" v-if="spinnerPotentialByBrand">
            <b-spinner style="width: 4rem; height: 4rem; color: #7336f0" label="Large Spinner"></b-spinner>
        </b-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data() {
            return {
                selectCriteria: [],
                format: null,
                SiteGroup: [],
                selectBrand: 'citroen',
                brands: [],
                postCodes: '',
                startDate: '',
                endDate: '',
                spinner: false,
                spinnerPotentialByBrand: false,
                channels: ['TF1', 'TMC', 'TFX', 'TF1 Series Film'],
                formats: ['30', '20', '15'],
                showAvailableImpressions: false,
                selectTask: null,
                tasks: [
                    {
                        text: 'Gestion des potentiels',
                        value: 'potentials-management',
                    },
                    {
                        text: 'Export des potentiels par marque',
                        value: 'potentials-export-by-brand',
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                getCriteriaTF1: 'app/getCriteriaTF1',
                getAvailableImpressions: 'app/getAvailableImpresssions',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            stateEndDate() {
                if (!this.startDate && !this.endDate) return true;
                return new Date(this.startDate).getFullYear() === new Date(this.endDate).getFullYear();
            },
            stateStartDate() {
                if (this.startDate && this.endDate) return this.startDate <= this.endDate;
                return true;
            },
            getNbDay() {
                let diff = new Date(this.endDate) - new Date(this.startDate);
                return diff / (1000 * 3600 * 24) + 1;
            },
            disabled() {
                return {
                    exportByBrand: !this.selectBrand || !this.selectCriteria || this.SiteGroup.length === 0 || !this.startDate || !this.endDate || !this.stateEndDate || !this.stateStartDate,
                    potentialsManagement: !this.postCodes || !this.startDate || !this.endDate || !this.stateEndDate || !this.stateStartDate,
                };
            },
        },
        watch: {
            startDate(newStartDate) {
                this.endDate = newStartDate;
            },
            endDate() {
                this.showAvailableImpressions = false;
            },
        },
        async mounted() {
            await this.fetchBrands();
            await this.getPotentialCriteriaTF1();
        },
        unmounted() {
            this.showAvailableImpressions = false;
        },
        methods: {
            ...mapActions('app', ['getAvailablePotentialTF1', 'getPotentialCriteriaTF1', 'getFullPotentialByBrandTF1', 'getBrands']),

            async getPotential() {
                this.spinner = true;
                this.showAvailableImpressions = true;
                let Format = this.formats[0].toString();
                let SiteGroup = this.SiteGroup;
                let WithBooking = true;
                let Data = Array.isArray(this.selectCriteria) ? this.selectCriteria : [this.selectCriteria];
                
                await this.getAvailablePotentialTF1({
                    CodesPostaux: this.postCodes,
                    Data: Data,
                    DateDebut: this.startDate,
                    DateFin: this.endDate,
                    Format,
                    SiteGroup,
                    WithBooking,
                });
                this.spinner = false;
            },
            async getPotentialByBrand() {
                this.spinnerPotentialByBrand = true;
                try {
                    let Format = this.formats[0].toString();
                    let SiteGroup = this.SiteGroup;
                    let WithBooking = true;
                    let Data = Array.isArray(this.selectCriteria) ? this.selectCriteria : [this.selectCriteria];

                    await this.getFullPotentialByBrandTF1({
                        brand: this.selectBrand,
                        email: localStorage.getItem('userEmail'),
                        dataTF1: {
                            Data: Data,
                            DateDebut: this.startDate,
                            DateFin: this.endDate,
                            Format,
                            SiteGroup,
                            WithBooking,
                        },
                    });

                    this.$toast(this.successToast('Vous recevrez le fichier CSV sur votre boîte email dans quelques minutes.'));
                } catch (e) {
                    console.log(e);
                    this.$toast(this.errorToast('Erreur lors du téléchargement'));
                }
                this.spinnerPotentialByBrand = false;
            },
            disabledStartDate(ymd, date) {
                const now = new Date();
                let day = date.setDate(date.getDate() + 1);
                return day <= now;
            },
            disabledEndDate(ymd, date) {
                const now = new Date();
                const startDate = new Date(this.startDate);
                let day = date.setDate(date.getDate() + 1);
                return day <= now || day <= startDate;
            },
            fetchBrands() {
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                });
            },
        },
    };
</script>
